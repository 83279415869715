<template>
  <div>
    <load-profile></load-profile>
    <main-tab />
    <b-card class="mb-2 vehicle">
      <b-row>
        <b-col class="text-right">
          <b-button
            v-if="!isUpdate"
            :variant="
              !$can('update', resource) || tabIndex === 2
                ? 'secondary'
                : 'success'
            "
            class="mb-1"
            @click="isUpdate = true"
            :disabled="!$can('update', resource) || tabIndex === 2 || loading"
          >
            {{ $t("button.update") }}
          </b-button>

          <n-button-loading
            v-if="isUpdate"
            type="button"
            class="mb-1 mr-1"
            variant="outline-secondary"
            :loading="loading"
            @submit="cancel"
          >
            {{ $t("button.cancel") }}
          </n-button-loading>
          <n-button-loading
            v-if="isUpdate"
            class="mb-1"
            :variant="
              !$can('update', resource) || tabIndex === 2
                ? 'secondary'
                : 'success'
            "
            :loading="loading"
            :disabled="!$can('update', resource) || tabIndex === 2"
            @submit="save"
          >
            {{ $t("button.save") }}
          </n-button-loading>
        </b-col>
      </b-row>
      <b-tabs pills nav-class="vehicle-main-tab" v-model="tabIndex">
        <b-tab>
          <template #title>
            <span class="font-weight-bold"> {{ $t("field.photos") }} </span>
          </template>

          <main-photo
            ref="mainPhoto"
            :is-update="isUpdate"
            v-if="tabIndex === 0"
            @success="isUpdate = false"
            @cancel="isUpdate = false"
          />
        </b-tab>

        <b-tab>
          <template #title>
            <span class="font-weight-bold">
              {{ $t("field.inspectionReport") }}
            </span>
          </template>

          <inspection-report
            ref="inspectionReport"
            :is-update="isUpdate"
            v-if="tabIndex === 1"
            @success="isUpdate = false"
            @cancel="isUpdate = false"
          />
        </b-tab>

        <b-tab>
          <template #title>
            <span class="font-weight-bold">
              {{ $t("field.demagePhotos") }}
            </span>
          </template>

          <demage-photo ref="demagePhoto" v-if="tabIndex === 2" />
        </b-tab>

        <b-tab>
          <template #title>
            <span class="font-weight-bold">
              {{ $t("field.inspectionNote") }}
            </span>
          </template>

          <div v-if="tabIndex === 3">
            <b-form-group :label="$t('field.inspectionNote')">
              <b-form-textarea
                v-model="data.inspectionNote"
                :disabled="!this.isUpdate"
                :rows="10"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTab,
  BTabs,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./updateFormInput";
import NButtonLoading from "@/components/NButtonLoading";
import MainTab from "./MainTab.vue";
import MainPhoto from "./mainPhoto/Index.vue";
import DemagePhoto from "./demagePhoto/Index.vue";
import InspectionReport from "./inspectionReport/Index.vue";

const VehicleRepository = Repository.get("vehicle");
const InspectionRepository = Repository.get("inspection");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTab,
    BTabs,
    BFormGroup,
    BFormTextarea,

    NFormConfirmation,
    NInput,
    NButtonLoading,
    MainTab,
    MainPhoto,
    DemagePhoto,
    InspectionReport,
  },
  watch: {
    tabIndex(value) {
      this.isUpdate = false;
      this.$router.replace({
        query: {
          tabIndex: value,
        },
      });
    },
  },
  data() {
    return {
      loading: false,
      tabIndex: this.$route.query.tabIndex
        ? Number(this.$route.query.tabIndex)
        : 0,
      isUpdate: false,
      data: {},
    };
  },
  created() {
    this.show();
  },
  methods: {
    save() {
      if (this.tabIndex == 0) {
        this.$refs.mainPhoto.save();
      } else if (this.tabIndex == 1) {
        this.$refs.inspectionReport.save();
      } else if (this.tabIndex == 3) {
        this.saveInspectionNote();
      }
    },
    cancel() {
      if (this.tabIndex == 0) {
        this.$refs.mainPhoto.cancel();
      } else if (this.tabIndex == 1) {
        this.$refs.inspectionReport.cancel();
      } else if (this.tabIndex == 3) {
        this.isUpdate = false;
      }
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
    saveInspectionNote() {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterSave"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: this.$t("button.save"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            InspectionRepository.updateInspectionNote({
              qrCode: this.data.qrcode,
              inspectionNote: this.data.inspectionNote,
            })
              .then((response) => {
                this.isUpdate = false;
                this.loading = false;
              })
              .catch((error) => {
                this.loading = false;
              });
          }
        });
    },
    show() {
      this.loading = true;
      VehicleRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = {
              ...data,
            };
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = FormInput;
    const resource = "vehicle";
    const route = "vehicle";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>
