var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"inspection-report"},[_c('div',{staticClass:"d-flex"},[_c('b-skeleton',{staticClass:"mr-1",attrs:{"type":"button"}}),_c('b-skeleton',{staticClass:"mr-1",attrs:{"type":"button"}}),_c('b-skeleton',{staticClass:"mr-1",attrs:{"type":"button"}})],1),_c('div',{staticClass:"mt-1"},[_c('b-skeleton-table',{attrs:{"columns":4,"rows":12,"borderless":"","striped":""}})],1)])]},proxy:true}])},[_c('div',[(_vm.isUpdate)?_c('div',[_c('update-inspection-report-form',{ref:"updateInspectionReportForm",attrs:{"answers":_vm.answers},on:{"cancel":_vm.cancelUpdate,"success":_vm.successUpdate}})],1):_c('div',[_c('video-player',{ref:"video"}),(_vm.answers.length)?_c('div',{staticClass:"inspection-report"},[_c('b-table-simple',{staticClass:"table-information mb-0",attrs:{"responsive":"","borderless":"","striped":"","sticky-header":""}},[_vm._l((_vm.inspectionForm),function(main,i){return [_c('b-thead',[_c('b-tr',[_c('b-th',{attrs:{"width":"50"}},[_vm._v("#")]),_c('b-th',[_vm._v(_vm._s(_vm.trans(main, "label", _vm.$i18n.locale)))]),_vm._l((main.options),function(option,j){return _c('b-th',{key:("option-" + i + "-" + j),staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.trans(option, "label", _vm.$i18n.locale))+" ")])})],2)],1),_c('b-tbody',_vm._l((main.points),function(point,j){return _c('b-tr',{key:("point-" + i + "-" + j),class:{
                na: _vm.noAnswers.includes(point.value.answer),
              }},[_c('b-td',[_vm._v(" "+_vm._s(j + 1)+" ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.trans(point, "label", _vm.$i18n.locale))+" "),(
                    point.value.failOptions &&
                    point.value.failOptions.length
                  )?_c('div',{staticClass:"fail-options"},[(
                      point.value.failMedia &&
                      _vm.checkIsImage(point.value.failMedia.fileType)
                    )?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.viewImage(point.value.failMedia)}}},[_c('feather-icon',{attrs:{"icon":"ImageIcon"}})],1):_vm._e(),(
                      point.value.failMedia &&
                      !_vm.checkIsImage(point.value.failMedia.fileType)
                    )?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.viewVideo(point.value.failMedia)}}},[_c('feather-icon',{attrs:{"icon":"VideoIcon"}})],1):_vm._e(),_vm._l((point.value.failOptions),function(failOption,k){return _c('b-badge',{key:("fail-option-" + i + "-" + j + "-" + k),staticClass:"text-capitalize",attrs:{"variant":"light-danger","pill":""}},[_vm._v(" "+_vm._s(_vm.trans( _vm.getFailOptions(failOption, point.failOptions), "label", _vm.$i18n.locale ))+" ")])})],2):_vm._e()]),(!_vm.noAnswers.includes(point.value.answer))?_c('b-td',{staticClass:"pass text-center"},[(point.value.answer == 1)?_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"22"}}):_vm._e()],1):_vm._e(),(!_vm.noAnswers.includes(point.value.answer))?_c('b-td',{staticClass:"fail text-center"},[(point.value.answer == 0)?_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"22"}}):_vm._e()],1):_vm._e(),(_vm.noAnswers.includes(point.value.answer))?_c('b-td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("field.na"))+" ")]):_vm._e()],1)}),1)]})],2)],1):_c('div',{staticClass:"text-center"},[_c('b-img',{staticClass:"w-50",attrs:{"src":require('@/assets/images/icons/no-data.svg'),"fluid":""}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }