<template>
  <div>
    <b-button variant="primary" size="sm" class="mb-2 d-none" @click="checkAll">
      Check All (For Testing)
    </b-button>

    <div class="inspection-report">
      <video-player ref="video" />
      <validation-observer ref="editForm" #default="{ invalid }">
        <n-form-confirmation ref="confirmForm" key="editForm" @submit="save" ok-variant="success" :form="$refs.editForm"
          :disabled="invalid">
          <b-table-simple responsive borderless striped sticky-header class="table-information mb-0">
            <template v-for="(main, i) in inspectionForm">
              <b-thead>
                <b-tr>
                  <b-th width="50">#</b-th>
                  <b-th>{{ trans(main, "label", $i18n.locale) }}</b-th>
                  <!-- <b-th class="text-center">
                    {{ $t("field.pass") }}
                  </b-th>
                  <b-th class="text-center">
                    {{ $t("field.fail") }}
                  </b-th> -->
                  <b-th class="text-center" v-for="(option, j) in main.options" :key="`option-${i}-${j}`">
                    {{ trans(option, "label", $i18n.locale) }}
                  </b-th>
                  <b-th class="text-center">
                    {{ $t("field.na") }}
                  </b-th>
                </b-tr>
              </b-thead>

              <b-tbody>
                <b-tr v-for="(point, j) in main.points" :key="`point-${i}-${j}`" :class="{
                  na: noAnswers.includes(point.value.answer),
                }">
                  <b-td>
                    {{ j + 1 }}
                  </b-td>
                  <b-td>
                    {{ trans(point, "label", $i18n.locale) }}
                    <div class="fail-options" v-if="
                      point.value.failOptions &&
                      point.value.failOptions.length
                    ">
                      <b-button variant="primary" size="sm" class="mr-1" v-if="
                        point.value.failMediaId &&
                        point.value.failMedia &&
                        checkIsImage(point.value.failMedia.fileType)
                      " @click="viewImage(point.value.failMedia)">
                        <feather-icon icon="ImageIcon" />
                      </b-button>
                      <b-button variant="primary" size="sm" class="mr-1" v-if="
                        point.value.failMediaId &&
                        point.value.failMedia &&
                        !checkIsImage(point.value.failMedia.fileType)
                      " @click="viewVideo(point.value.failMedia)">
                        <feather-icon icon="VideoIcon" />
                      </b-button>
                      <b-badge class="text-capitalize" variant="light-danger" pill
                        v-for="(failOption, k) in point.value.failOptions" :key="`fail-option-${i}-${j}-${k}`">
                        {{
                          trans(
                            getFailOptions(failOption, point.failOptions),
                            "label",
                            $i18n.locale
                          )
                        }}
                      </b-badge>
                    </div>
                  </b-td>
                  <b-td class="pass text-center">
                    <b-form-checkbox :name="`option-${i}-${j}`" v-model="point.value.answer" :value="1" plain
                      @change="onChangeOption(1, i, j, $event)" size="lg"></b-form-checkbox>
                  </b-td>
                  <b-td class="fail text-center">
                    <b-form-checkbox :name="`option-${i}-${j}`" v-model="point.value.answer" :value="0" plain
                      @change="onChangeOption(0, i, j, $event)" size="lg"></b-form-checkbox>
                  </b-td>
                  <b-td class="fail text-center">
                    <b-form-checkbox :name="`option-${i}-${j}`" v-model="point.value.answer" :value="2" plain
                      @change="onChangeOption(2, i, j, $event)" v-if="!point.required" size="lg"></b-form-checkbox>
                  </b-td>
                </b-tr>
              </b-tbody>
            </template>
          </b-table-simple>
        </n-form-confirmation>
      </validation-observer>
    </div>

    <fail-option-modal ref="failOptionModal" @save="saveFailOption" />
  </div>
</template>

<script>
  import {
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
    BBadge,
    BButton,
    BFormRadio,
    BFormCheckbox,
  } from "bootstrap-vue";
  import inspectionForm from "@/data/inspectionForm";
  import Repository from "@/repositories/RepositoryFactory";
  import VideoPlayer from "@/components/VideoPlayer.vue";
  import FailOptionModal from "./FailOptionModal";
  import NFormConfirmation from "@/components/NFormConfirmation";
  import NButtonLoading from "@/components/NButtonLoading.vue";

  const InspectionRepository = Repository.get("inspection");

  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BTabs,
      BTab,
      BTableSimple,
      BThead,
      BTbody,
      BTr,
      BTh,
      BTd,
      BImg,
      BBadge,
      BButton,
      BFormRadio,
      BFormCheckbox,

      VideoPlayer,
      FailOptionModal,
      NFormConfirmation,
      NButtonLoading,
    },
    props: {
      answers: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    data() {
      return {
        inspectionForm: [...JSON.parse(JSON.stringify(inspectionForm))],
        loading: false,
        noAnswers: [2],
        uploadImageOption: [],
      };
    },
    mounted() {
      this.mergeAnswer();
    },
    methods: {
      checkAll() {
        let answers = [];
        this.inspectionForm.forEach((form) => {
          form.subs.forEach((sub) => {
            sub.points.forEach((point) => {
              answers.push({
                answer: 1,
                failMedia: null,
                failMediaId: null,
                failOptions: [],
                key: point.key,
              });
            });
          });
        });

        let sourceArray = [...answers];
        let destinationArray = this.inspectionForm;
        sourceArray.forEach((sourceItem) => {
          destinationArray.forEach((destinationItem) => {
            destinationItem.subs.forEach((subItem) => {
              const pointIndex = subItem.points.findIndex(
                (pointItem) => pointItem.key === sourceItem.key
              );
              if (pointIndex !== -1) {
                subItem.points[pointIndex] = {
                  ...subItem.points[pointIndex],
                  value: sourceItem,
                };
              }
            });
          });
        });
        this.inspectionForm = [...JSON.parse(JSON.stringify(destinationArray))];
      },
      submit() {
        if (!this.isChange()) {
          this.$emit("cancel");
          return;
        }

        this.$refs.editForm.validate().then((success) => {
          if (success) {
            this.$refs.confirmForm.confirm();
          }
        });
      },
      save() {
        this.loading = true;
        let data = [];
        this.inspectionForm.forEach((form) => {
          form.points.forEach((point) => {
            if (
              point.value.answer == 0 ||
              point.value.answer == 1 ||
              point.value.answer == 2
            ) {
              data.push(point.value);
            }
          });
        });
        InspectionRepository.update(this.$route.params.id, data)
          .then((response) => {
            this.$emit("success", this.items);
            this.items = [];
          })
          .catch((error) => {
            this.loading = false;
          });
      },
      isChange() {
        let newAnswers = [];
        this.inspectionForm.forEach((form) => {
          form.points.forEach((point) => {
            if (
              point.value.answer == 0 ||
              point.value.answer == 1 ||
              point.value.answer == 2
            ) {
              newAnswers.push(point.value);
            }
          });
        });

        newAnswers.sort((a, b) => {
          if (a.key < b.key) {
            return -1;
          } else if (a.key > b.key) {
            return 1;
          } else {
            return 0;
          }
        });

        return JSON.stringify(this.answers) != JSON.stringify(newAnswers);
      },
      cancel() {
        if (this.isChange()) {
          this.$bvModal
            .msgBoxConfirm(this.$t("alert.hasUnsavedChange"), {
              title: this.$t("alert.areYouSure"),
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: this.$t("button.discardChanged"),
              cancelTitle: this.$t("button.cancel"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              if (value) {
                this.$emit("cancel");
              }
            });
        } else {
          this.$emit("cancel");
        }
      },
      onChangeOption(answer, i, j, evt) {
        let point = this.inspectionForm[i].points[j];
        if (answer == 0) {
          point.value.key = point.key;
          point.value.answer = !point.modalOnYes ? null : answer;
          point.value.failOptions = [];
          point.value.failMediaId = null;
          point.value.failMedia = null;
          console.log({ point });

          if (!point.modalOnYes) {
            this.$refs.failOptionModal.show(point, i, j);
          }
        } else if (answer == 1 || answer == 2) {
          point.value.key = point.key;
          point.value.answer = point.modalOnYes ? null : answer;
          point.value.failOptions = [];
          point.value.failMediaId = null;
          point.value.failMedia = null;

          if (point.modalOnYes && answer == 1) {

            console.log({ point, answer });
            if (["flooded_vehicle", "fire_damaged_vehicle", "total_repaint_repainted"].indexOf(point.key) > -1) {
              console.log(evt);

              if (evt)
                this.$refs.failOptionModal.show(point, i, j);
            }
            else
              this.$refs.failOptionModal.show(point, i, j);
          }
        }
      },
      saveFailOption(value, i, j) {
        let point = this.inspectionForm[i].points[j];
        point.value.answer = value.answer;
        point.value.failOptions = value.failOptions;
        point.value.failMediaId = value.failMediaId;
        point.value.failMedia = value.failMedia;
      },
      mergeAnswer() {
        let sourceArray = [...this.answers];
        let destinationArray = this.inspectionForm;
        sourceArray.forEach((sourceItem) => {
          destinationArray.forEach((destinationItem) => {
            const pointIndex = destinationItem.points.findIndex(
              (pointItem) => pointItem.key === sourceItem.key
            );
            if (pointIndex !== -1) {
              destinationItem.points[pointIndex] = {
                ...destinationItem.points[pointIndex],
                value: sourceItem,
              };
            }
          });
        });
        this.inspectionForm = [...JSON.parse(JSON.stringify(destinationArray))];
      },
      countAllPoints(main) {
        return main.subs.reduce((acc, sub) => {
          return acc + sub.points.length;
        }, 0);
      },
      countPassAnswers(points) {
        return points.reduce((accumulator, currentValue) => {
          if (
            currentValue.value.answer == 0 ||
            currentValue.value.answer == 1 ||
            currentValue.value.answer == 2
          ) {
            accumulator++;
          }
          return accumulator;
        }, 0);
      },
      countPassAnswersFromSub(subs) {
        return subs.reduce((acc, curr) => {
          const filteredPoints = curr.points.filter(
            (point) =>
              point.value.answer === 0 ||
              point.value.answer === 1 ||
              point.value.answer === 2
          );
          return acc + filteredPoints.length;
        }, 0);
      },
      getFailOptions(fail, options) {
        return options.find((obj) => obj.value === fail);
      },
      checkIsImage(fileType) {
        return fileType.startsWith("image/");
      },
      viewImage(media) {
        this.$imagePreview({
          initIndex: 0,
          images: [media.fileUrl],
          zIndex: 9999,
          isEnableDownloadImage: false,
          isEnableImagePageIndicator: false,
          isEnableBlurBackground: true,
          onClose: () => { },
        });
      },
      viewVideo(media) {
        this.$refs.video.play(media.fileUrl);
      },
    },
    setup() {
      const resource = "vehicle";
      const route = "vehicle";

      return {
        resource,
        route,
      };
    },
  };
</script>
