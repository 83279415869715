export default [
    {
        "nameEn": "Front Left",
        "nameKm": "Front Left",
        "key": "front_left",
        "preImage": "m_front_left.png"
    },
    {
        "nameEn": "Front Left HeadLamp",
        "nameKm": "Front Left HeadLamp",
        "key": "front_left_headlamp",
        "preImage": "m_front_left_headlamp.png"
    },
    {
        "nameEn": "Front Left Fog Lamp",
        "nameKm": "Front Left Fog Lamp",
        "key": "front_left_fog_lamp",
        "preImage": "m_front_left_fog_lamp.png"
    },
    {
        "nameEn": "Front Left Tyre",
        "nameKm": "Front Left Tyre",
        "key": "front_left_tyre",
        "preImage": "m_front_left_tyre.png"
    },
    {
        "nameEn": "Front Left Pillar Frame",
        "nameKm": "Front Left Pillar Frame",
        "key": "front_left_pillar_frame",
        "preImage": "m_front_left_pillar_frame.png"
    },
    {
        "nameEn": "Front Seat",
        "nameKm": "Front Seat",
        "key": "front_seat",
        "preImage": "m_front_seat.png"
    },
    {
        "nameEn": "Odometer",
        "nameKm": "Odometer",
        "key": "odometer",
        "preImage": "m_odometer.png"
    },
    {
        "nameEn": "Air Conditioner Temperature",
        "nameKm": "Air Conditioner Temperature",
        "key": "air_con_temporature",
        "preImage": "m_air_conditioner_temperature.png"
    },
    {
        "nameEn": "4x4 Gear Knob / Switch",
        "nameKm": "4x4 Gear Knob / Switch",
        "key": "gear_knob",
        "preImage": "m_gear_knob.png"
    },
    {
        "nameEn": "Pedal",
        "nameKm": "Pedal",
        "key": "pedal",
        "preImage": "m_pedal.png"
    },
    {
        "nameEn": "Rear Left Pillar Frame",
        "nameKm": "Rear Left Pillar Frame",
        "key": "rear_left_pillar_frame",
        "preImage": "m_rear_left_pillar_frame.png"
    },
    {
        "nameEn": "Rear Seat",
        "nameKm": "Rear Seat",
        "key": "rear_seat",
        "preImage": "m_rear_seat.png"
    },
    {
        "nameEn": "3rd Row Seat",
        "nameKm": "3rd Row Seat",
        "key": "three_row_seat",
        "preImage": "m_row_seat.png"
    },
    {
        "nameEn": "Dashboard",
        "nameKm": "Dashboard",
        "key": "dashboard",
        "preImage": "m_dashboard.png"
    },
    {
        "nameEn": "Rear Left Tyre",
        "nameKm": "Rear Left Tyre",
        "key": "rear_left_tyre",
        "preImage": "m_rear_left_tyre.png"
    },
    {
        "nameEn": "Left Taillamp",
        "nameKm": "Left Taillamp",
        "key": "left_taillamp",
        "preImage": "m_left_taillamp.png"
    },
    {
        "nameEn": "Rear Left",
        "nameKm": "Rear Left",
        "key": "rear_left",
        "preImage": "m_rear_left.png"
    },
    {
        "nameEn": "Rear Centre",
        "nameKm": "Rear Centre",
        "key": "rear_centre",
        "preImage": "m_rear_centre.png"
    },
    {
        "nameEn": "Rear Bonnet",
        "nameKm": "Rear Bonnet",
        "key": "rear_bonnet",
        "preImage": "m_rear_bonnet.png"
    },
    {
        "nameEn": "Roof",
        "nameKm": "Roof",
        "key": "roof",
        "preImage": "m_roof.png"
    },
    {
        "nameEn": "Spare Tyre",
        "nameKm": "Spare Tyre",
        "key": "spare_tyre",
        "preImage": "m_spare_tyre.png"
    },
    {
        "nameEn": "Tools Set",
        "nameKm": "Tools Set",
        "key": "tools_set",
        "preImage": "m_tools_set.png"
    },
    {
        "nameEn": "Rear Right",
        "nameKm": "Rear Right",
        "key": "rear_right",
        "preImage": "m_rear_right.png"
    },
    {
        "nameEn": "Right Taillamp",
        "nameKm": "Right Taillamp",
        "key": "right_taillamp",
        "preImage": "m_right_taillamp.png"
    },
    {
        "nameEn": "Rear Right Tyre",
        "nameKm": "Rear Right Tyre",
        "key": "rear_right_tyre",
        "preImage": "m_rear_right_tyre.png"
    },
    {
        "nameEn": "Rear Right Pillar Frame",
        "nameKm": "Rear Right Pillar Frame",
        "key": "rear_right_pillar_frame",
        "preImage": "m_rear_right_pillar_frame.png"
    },
    {
        "nameEn": "Front Right Pillar Frame",
        "nameKm": "Front Right Pillar Frame",
        "key": "front_right_pillar_frame",
        "preImage": "m_front_right_pillar_frame.png"
    },
    {
        "nameEn": "Front Right Tyre",
        "nameKm": "Front Right Tyre",
        "key": "front_right_tyre",
        "preImage": "m_front_right_tyre.png"
    },
    {
        "nameEn": "Right Headlamp",
        "nameKm": "Right Headlamp",
        "key": "right_headlamp",
        "preImage": "m_right_headlamp.png"
    },
    {
        "nameEn": "Front Right Fog Lamp",
        "nameKm": "Front Right Fog Lamp",
        "key": "front_right_fog_lamp",
        "preImage": "m_front_right_fog_lamp.png"
    },
    {
        "nameEn": "Front Right",
        "nameKm": "Front Right",
        "key": "front_right",
        "preImage": "m_front_right.png"
    },
    {
        "nameEn": "Front Centre",
        "nameKm": "Front Centre",
        "key": "front_centre",
        "preImage": "m_front_centre.png"
    },
    {
        "nameEn": "Engine Bay",
        "nameKm": "Engine Bay",
        "key": "engine_bay",
        "preImage": "m_engine_bay.png"
    },
    {
        "nameEn": "Engine Oil Cap",
        "nameKm": "Engine Oil Cap",
        "key": "engine_oil_cap",
        "preImage": "m_engine_oil_cap.png"
    },
    {
        "nameEn": "Engine Dipstick",
        "nameKm": "Engine Dipstick",
        "key": "engine_dipstick",
        "preImage": "m_engine_dipstick.png"
    },
    {
        "nameEn": "Car Key",
        "nameKm": "Car Key",
        "key": "car_key",
        "preImage": "m_car_key.png"
    },
    {
        "nameEn": "Vin Number",
        "nameKm": "Vin Number",
        "key": "vin_number",
        "preImage": "m_vin_number.png"
    }
]