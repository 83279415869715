<template>
  <div>
    <b-modal
      id="fail-option-modal"
      cancel-variant="outline-secondary"
      centered
      no-close-on-backdrop
      :title="trans(point, 'label', $i18n.locale)"
      @ok="handleOk"
    >
      <validation-observer ref="failOptionForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          ref="formConfirmation"
          key="failOptionForm"
          :form="$refs.failOptionForm"
          :disabled="invalid"
          @submit="submit"
        >
          <validation-provider
            #default="{ errors }"
            :vid="`fail-option`"
            :name="`field.failOption`"
            :rules="`required`"
            v-if="point.failOptions && point.failOptions.length"
          >
            <b-form-group
              :label="$t('field.failOption')"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-checkbox-group
                id="fail-option"
                name="fail-option"
                v-model="value.failOptions"
                stacked
              >
                <b-form-checkbox
                  :value="option.value"
                  v-for="(option, idx) in point.failOptions"
                  :key="`option-${idx}`"
                  class="text-capitalize"
                >
                  {{ trans(option, "label", $i18n.locale) }}
                </b-form-checkbox>
              </b-form-checkbox-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group v-if="point.requiredImage">
            <label class="w-100">
              {{ $t("field.file") }}
              <span class="text-muted" v-if="point.requiredImage == 2">
                - {{ $t("field.optional") }}
              </span>
            </label>
            <validation-provider
              #default="{ errors }"
              :vid="`media`"
              name="field.file"
              :rules="point.requiredImage == 1 ? `required` : ``"
            >
              <n-file-uploader
                type="file"
                :ref="`file`"
                :name="`file`"
                :state="errors.length > 0 ? false : null"
                :browse-text="$t('button.browse')"
                :placeholder="$t('button.chooseFileOrDropHere')"
                :drop-placeholder="$t('button.dropHere')"
                :item="value.failMedia"
                accept=".pdf,.jpg,.jpeg,.png,video/*"
                @change="onFileChange"
              ></n-file-uploader>
              <input
                :name="`media`"
                type="hidden"
                v-model="value.failMediaId"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </n-form-confirmation>
      </validation-observer>

      <template #modal-ok>
        <b-spinner small label="Spinning" v-if="loading"></b-spinner>
        <span v-else>{{ $t("button.save") }}</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect.vue";
import Repository from "@/repositories/RepositoryFactory";
import NFileUploader from "@/components/NFileUploader.vue";

const FileRepository = Repository.get("file");

export default {
  components: {
    BModal,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BSpinner,

    NFormConfirmation,
    NInput,
    NAsyncSingleSelect,
    NFileUploader,
  },
  watch: {},
  data() {
    return {
      loading: false,
      point: {
        labelEn: "",
        labelKm: "",
      },
      value: {
        failOptions: [],
      },
      i: null,
      j: null,
    };
  },
  methods: {
    show(point, i, j) {
      this.point = {
        ...point,
      };
      this.value = {
        ...point.value,
        failMediaId: null,
        failMedia: {
          fileName: "",
          fileType: "",
          fileUrl: "",
          mediaId: "",
        },
      };
      this.i = i;
      this.j = j;

      this.$bvModal.show("fail-option-modal");
    },
    hide() {
      this.$bvModal.hide("fail-option-modal");
    },

    onFileChange(event) {
      this.loading = true;
      let formData = new FormData();
      formData.append("file", event);
      this.$refs[`file`].startLoading();
      FileRepository.uploadStream(formData)
        .then((response) => {
          const res = response?.data?.data;
          if (res) {
            this.value.failMediaId = res.mediaId;
            this.value.failMedia.fileName = res.fileName;
            this.value.failMedia.fileType = res.fileType;
            this.value.failMedia.fileUrl = res.fileUrl;
            this.value.failMedia.mediaId = res.mediaId;
          }
        })
        .catch()
        .then(() => {
          this.$refs[`file`].stopLoading();
          this.loading = false;
        });
    },

    handleOk(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      this.$refs.failOptionForm.validate().then((success) => {
        if (success) {
          // this.$refs.formConfirmation.confirm();
          this.submit();
        }
      });
    },
    submit() {
      this.$emit(
        "save",
        {
          ...this.value,
          answer: this.point.modalOnYes ? 1 : 0,
        },
        this.i,
        this.j,
      );
      this.hide();
    },
  },
};
</script>