<template>
  <div>
    <b-modal id="modal-video" hide-footer centered size="lg" @hidden="onHide">
      <b-embed
        v-if="videoUrl"
        type="iframe"
        aspect="16by9"
        :src="videoUrl"
        allowfullscreen
        autoplay
      ></b-embed>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BEmbed } from "bootstrap-vue";

export default {
  components: { BModal, BEmbed },
  data() {
    return {
      videoUrl: "",
    };
  },
  methods: {
    play(videoUrl) {
      this.videoUrl = videoUrl;
      this.$bvModal.show("modal-video");
    },
    onHide() {
      this.videoUrl = "";
      this.$bvModal.hide("modal-video");
    },
  },
};
</script>